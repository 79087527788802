import React, { useState } from 'react';
import Tesseract from 'tesseract.js';
import './App.css';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true); // Change this based on subscription status

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map(file => {
      const imageUrl = URL.createObjectURL(file);
      return { name: file.name, url: imageUrl, size: (file.size / 1024 / 1024).toFixed(2) + ' MB' };
    });
    setUploadedImages(prevImages => [...prevImages, ...newImages]);
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newImages = files.map(file => {
      const imageUrl = URL.createObjectURL(file);
      return { name: file.name, url: imageUrl, size: (file.size / 1024 / 1024).toFixed(2) + ' MB' };
    });
    setUploadedImages(prevImages => [...prevImages, ...newImages]);
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleConvert = () => {
    if (selectedImages.length > 0) {
      setLoading(true);
      const promises = selectedImages.map(image =>
        Tesseract.recognize(
          image.url,
          'eng',
          { logger: m => console.log(m) }
        ).then(({ data: { text } }) => ({
          name: image.name,
          text
        }))
      );
      Promise.all(promises).then(results => {
        setResults(results);
        setLoading(false);
      });
    }
  };

  const handleClearAll = () => {
    setSelectedImages([]);
    setUploadedImages([]);
    setResults([]);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Text copied to clipboard!');
    });
  };

  const handleDownloadWord = () => {
    const zip = new JSZip();
    results.forEach(result => {
      zip.file(`${result.name}.txt`, result.text);
    });
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, 'converted-texts.zip');
      });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="App">
      <h1>{t('image_to_text_converter')}</h1>
      <p>{t('upload_description')}</p>
      <div className="language-switcher">
        <select onChange={(e) => changeLanguage(e.target.value)} defaultValue="en">
          <option value="en">English</option>
          <option value="hi">Hindi</option>
          <option value="gu">Gujrati</option>

          {/* <option value="es">Español</option> */}
          
          {/* Add more languages as needed */}
        </select>
      </div>
      <div className="container">
        <div
          className="upload-box"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className="upload-section">
            <input
              type="file"
              id="file-upload"
              accept="image/*"
              multiple={isSubscribed} // Allow multiple uploads if subscribed
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
            <label htmlFor="file-upload">
              <div className="upload-content">
                <img src="upload-placeholder.png" alt="Upload" />
                <h2>{t('drag_drop_upload')}</h2>
                <p>{t('supported_formats')}</p>
                <button onClick={() => document.getElementById('file-upload').click()}>{t('browse')}</button>
              </div>
            </label>
          </div>
        </div>

        <div className="preview-section">
          {uploadedImages.length > 0 && (
            <div className="uploaded-files">
              {uploadedImages.map((image, index) => (
                <div key={index} className="uploaded-file">
                  <img src={image.url} alt={image.name} />
                  <div>
                    <p>{image.name}</p>
                    <p2>{image.size}</p2>
                  </div>
                </div>
              ))}
            </div>
          )}
          {uploadedImages.length > 0 && (
            <div className="action-buttons">
              <button onClick={handleClearAll}>{t('clear_all')}</button>
              <button onClick={handleConvert}>{t('convert')}</button>
            </div>
          )}
        </div>
      </div>

      {loading && <p className="loading">{t('loading')}</p>}
      {results.length > 0 && (
        <div className="result">
          <h2>{t('results')}:</h2>
          {results.map((result, index) => (
            <div key={index} className="result-item">
              <h3>{result.name}</h3>
              <p>{result.text}</p>
              <button onClick={() => handleCopyText(result.text)}>{t('copy')}</button>
            </div>
          ))}
          <button onClick={handleDownloadWord}>{t('download')}</button>
        </div>
      )}
    </div>
  );
}

export default App;
