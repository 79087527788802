import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "image_to_text_converter": "Image to Text Converter",
      "upload_description": "An online image to text converter to extract text from images. Upload your photo, to get text file instantly",
      "drag_drop_upload": "Drag & Drop, Upload or Paste image",
      "supported_formats": "Supported formats: JPG, PNG, GIF, JFIF (JPEG), PDF",
      "browse": "Browse",
      "clear_all": "Clear All",
      "convert": "Convert",
      "loading": "Loading...",
      "result": "Result",
      "copy": "Copy",
      "download": "Download"
    }
  },
  es: {
    translation: {
      "image_to_text_converter": "Convertidor de Imagen a Texto",
      "upload_description": "Un convertidor de imagen a texto en línea para extraer texto de imágenes. Sube tu foto para obtener el archivo de texto al instante",
      "drag_drop_upload": "Arrastra y suelta, sube o pega la imagen",
      "supported_formats": "Formatos soportados: JPG, PNG, GIF, JFIF (JPEG), PDF",
      "browse": "Examinar",
      "clear_all": "Borrar Todo",
      "convert": "Convertir",
      "loading": "Cargando...",
      "result": "Resultado",
      "copy": "Copiar",
      "download": "Descargar"
    }
  },
  hi: {
    translation: {
      "image_to_text_converter": "चित्र से पाठ कनवर्टर",
      "upload_description": "छवियों से पाठ निकालने के लिए एक ऑनलाइन छवि से पाठ कनवर्टर। अपनी फोटो अपलोड करें, तुरंत पाठ फ़ाइल प्राप्त करें",
      "drag_drop_upload": "ड्रैग और ड्रॉप करें, अपलोड करें या छवि पेस्ट करें",
      "supported_formats": "समर्थित प्रारूप: JPG, PNG, GIF, JFIF (JPEG), PDF",
      "browse": "ब्राउज़ करें",
      "clear_all": "सभी साफ करें",
      "convert": "कनवर्ट करें",
      "loading": "लोड हो रहा है...",
      "result": "परिणाम",
      "copy": "कॉपी करें",
      "download": "डाउनलोड करें"
    }
  },
  gu: {
    translation: {
      "image_to_text_converter": "છબીથી લખાણ કન્વર્ટર",
      "upload_description": "છબીઓમાંથી લખાણ કાઢવા માટેનું એક ઑનલાઇન છબીથી લખાણ કન્વર્ટર. તમારો ફોટો અપલોડ કરો, તરત જ લખાણ ફાઇલ મેળવો",
      "drag_drop_upload": "ડ્રેગ અને ડ્રોપ કરો, અપલોડ કરો અથવા છબી પેસ્ટ કરો",
      "supported_formats": "સપોર્ટેડ ફોર્મેટ્સ: JPG, PNG, GIF, JFIF (JPEG), PDF",
      "browse": "બ્રાઉઝ કરો",
      "clear_all": "બધું સાફ કરો",
      "convert": "કન્વર્ટ કરો",
      "loading": "લોડ થઈ રહ્યું છે...",
      "result": "પરિણામ",
      "copy": "નકલ કરો",
      "download": "ડાઉનલોડ કરો"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
